
import { getCharNotifyDays } from "@evercam/shared/utils"
import weekDays from "@evercam/shared/constants/weekDays"
import ProgressPhotosHeader from "@/components/progressPhoto/progressPhotosHeaders"
import ResizeTable from "@/mixins/resizeTable"
import { mapStores } from "pinia"
import { useProgressPhotoStore } from "@/stores/progressPhotos"
import { useConnectorStore } from "@/stores/connector"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
  ProgressPhotoDialogType,
} from "@evercam/shared/types"
import ProgressPhotoDialog from "@/components/progressPhoto/ProgressPhotoDialog"

export default {
  meta: {
    pageId: AnalyticsEventPageId.progressPhotos,
  },
  components: {
    ProgressPhotoDialog,
    EvercamLoadingAnimation,
  },
  mixins: [ResizeTable],
  data() {
    return {
      headers: ProgressPhotosHeader,
      progressPhotosList: {},
      days: null,
      isProcessing: true,
    }
  },
  async fetch() {
    await this.progressPhotoStore.getProgressPhotos()
  },
  head() {
    return {
      title: "Evercam | Progress Photos",
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useProgressPhotoStore, useConnectorStore),
  },
  watch: {
    "progressPhotoStore.progressPhotos"(val) {
      this.formatProgressPhotosToArray(val)
      this.isProcessing = false
    },
    "progressPhotoStore.progressPhotoDialog"(visible) {
      if (this.progressPhotoStore.isEditDialog) {
        this.$analytics.saveEvent(
          AnalyticsEvent.progressPhotoToggleEditDialog,
          { visible }
        )
      } else {
        this.$analytics.saveEvent(
          AnalyticsEvent.progressPhotoToggleCreateDialog,
          { visible }
        )
      }
    },
  },
  mounted() {
    this.$setTimeout(this.onResize, 1500)
    this.$analytics.saveEvent(AnalyticsEvent.pageView)
  },
  created() {
    this.days = weekDays
    this.formatProgressPhotosToArray(this.progressPhotoStore.progressPhotos)
  },
  methods: {
    getCharNotifyDays,
    openCreateDialog() {
      this.progressPhotoStore.progressPhotoDialogType =
        ProgressPhotoDialogType.Create
      this.progressPhotoStore.progressPhotoDialog = true
    },
    openEditDialog(id) {
      this.progressPhotoStore.progressPhotoId = id
      this.progressPhotoStore.progressPhotoDialogType =
        ProgressPhotoDialogType.Edit
      this.progressPhotoStore.progressPhotoDialog = true
    },
    getProcoreProject(item) {
      const project = this.connectorStore.procoreUserProjects.find(
        (p) => item?.companyId === p.company.id && item?.projectId === p.id
      )
      if (!project) {
        return
      }

      return `${project.name} (${project.company.name})`
    },
    formatProgressPhotosToArray(data) {
      const stringToArray = (text) => text?.split(",") || ""
      this.progressPhotosList = data.reduce(
        (acc, item) => [
          {
            ...item,
            createdAt: this.$moment
              .tz(item.createdAt, item.timeZone)
              .format("DD MMM YYYY HH:mm:ss"),
            numberOfCams: stringToArray(item.cameraIds),
            cameraIds: stringToArray(item.cameraIds),
            cameras: stringToArray(item.cameraNames),
            provider: item.provider,
            config: item.config,
            notifyDays: stringToArray(item.notifyDays),
          },
          ...acc,
        ],
        []
      )
    },
    getProviderName(provider) {
      if (!provider) {
        return ""
      }

      return `${provider.charAt(0).toUpperCase()}${provider.slice(1)}`
    },
  },
}
